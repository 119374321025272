<template>
    <transition name="slide">
        <CCard>
            <CCardBody>
                <h3>Transfer funds from partner to Stayify</h3>
                <CAlert :color="alertType" v-if="message">
                    {{ message }}
                </CAlert>
                <div v-if="isLoadingRequired === false">
                    <CRow>
                        <CCol sm="5" v-if="user?.hasGlobalRights == true">
                            <CSelect label="Partners" :value.sync="partnerId" :plain="true" :options="partners"
                                placeholder="Select a partner">
                            </CSelect>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol sm="5" v-if="user?.hasGlobalRights == true">
                            <CSelect label="Currencies" :value.sync="currencyId" :plain="true" :options="currencies"
                                placeholder="Select a currency">
                            </CSelect>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol sm="5">
                            <CInput label="Transfer reason" type="text" v-model="transferReason" />
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol sm="5">
                            <CInput label="Amount" type="number" v-model="amount" />
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol sm="5" style="margin-top:20px">
                            <CButton color="primary" @click="generateTransaction" class="btn-lg">
                                <CIcon name="cil-save" /> Transfer
                            </CButton>
                        </CCol>
                    </CRow>
                </div>
                <CSpinner style="display: block; position: fixed; top: 50%; left: 50%" v-if="isLoadingRequired === true"
                    color="primary" />
            </CCardBody>
        </CCard>
    </transition>
</template>

<script>
import axios from "axios";
export default {
  name: "TransactionReports",
  data: () => ({
    message: null,
    partnerId: "00000000-0000-0000-0000-000000000000",
    currencyId: "00000000-0000-0000-0000-000000000000",
    partners: [],
    currencies: [],
    alertType: "danger",
    from: null,
    amount: '',
    transferReason: null,
    user: null,
    isLoadingRequired: false
  }),
  watch: {
    amount(newValue) {
      this.amount = this.formatAmount(newValue);
    }
  },
  methods: {
    generateTransaction() {
      let self = this;
      self.isLoadingRequired = true;

      if (self.amount == null || self.amount == undefined) {
        self.message = "Amount is mandatory";
        self.isLoadingRequired = false;
        return;
      }
      if (this.partnerId == null || this.partnerId == undefined) {
        self.message = "Please select a partner";
        self.isLoadingRequired = false;
        return;
      }
      if (this.transferReason == null || this.transferReason == undefined) {
        self.message = "Please add a reason";
        self.isLoadingRequired = false;
        return;
      }

      let url = this.$apiAdress + "/v1/Booking/initiate-transfer-stayify-fee/" + this.partnerId + "/" + this.amount + "/" + this.currencyId+ "/" + this.transferReason;

      axios.get(url)
        .then(function (response) {
          self.isLoadingRequired = false;
        })
        .catch(function (error) {
          self.isLoadingRequired = false;
        });
    },
    getPartners() {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/Partner")
        .then(function (response) {
          response.data.map(function (value, key) {
            value.value = value.id;
            value.label = value.name;
            self.partners.push(value);
          });
        })
        .catch(function (error) {
          this.message = error;
          console.log(error);
        });

      var user = localStorage.getItem("user");
      if (user != null) {
        self.user = JSON.parse(localStorage.getItem("user"));
      }
    },
    getCurrencies() {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/Currency")
        .then(function (response) {
          response.data.map(function (value, key) {
            value.value = value.id;
            value.label = value.name;
            self.currencies.push(value);
          });
        })
        .catch(function (error) {
          this.message = error;
          console.log(error);
        });
    },
    formatAmount(value) {
        return value.replace(/,/g, '.').replace(/[^0-9.]/g, '');
    },
  },
  mounted: function () {
    this.getPartners();
    this.getCurrencies();
  }
}
</script>
